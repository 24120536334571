<template>
  <b-card
    no-body
    class="pa-0"
  >
    <div class="d-flex justify-content-end m-1">
      <b-button
        v-if="!loader"
        variant="primary"
        class="ma-1"
        @click="updateService"
      >
        <span class="text-nowrap"> Update Services </span>
      </b-button>
      <b-button
        v-if="loader"
        variant="primary"
        disabled
        class="mr-1"
      >
        <b-spinner
          small
          type="grow"
        />
        Loading...
      </b-button>
    </div>
    <general-table
      ref="damenService"
      :api-url="apiUrl"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      :delete-content="false"
      :selectable="false"
      :export-table="false"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template #cell(image)="data">

        <img
          v-if="data.item.image"
          :src="url+data.item.image"
          width="80"
        >
        <span v-else>   <feather-icon
          icon="ImageIcon"
          size="18"
        />
        </span>
      </template>
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
      <template #filterServices>

        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.cat_id "
              value-field="id"
              text-field="name"
              :options="categories"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Category
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.cat_id ">
              <b-button
                size="sm"
                @click="filter.cat_id = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.biller_id "
              value-field="id"
              text-field="name"
              :options="billers"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Biller
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.biller_id ">
              <b-button
                size="sm"
                @click="filter.biller_id = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.damenService.getAllData()"
        >
          Filter
        </b-button>

      </template>
    </general-table>

  </b-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'serviceAggregators',
      type: 'modal',
      url: 'https://v2.zerocash.co/storage/',
      viewContent: false,
      loader: false,
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'service_code', label: 'Service Code' },
        { key: 'cat', label: 'Category' },
        { key: 'biller', label: 'Biller' },
        { key: 'created_at', label: 'Created At' },
      ],
      filter: {},
      types: '',
      categories: [],
      billers: [],
    }
  },
  computed: {
    apiUrl() {
      let base = 'serviceAggregators?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },

  },
  mounted() {
    this.getCategories()
    this.getBillers()
  },
  methods: {
    timeFormat(date) {
      return moment(date).format(' DD MMM YYYY')
    },
    updateService() {
      this.loader = true
      axios.post('service_aggregators/update').then(res => {
        if (res.status === 200 || res.status === 201) {
          this.loader = false
          this.$refs.damenService.getAllData()
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        } else {
          this.loader = false
          this.$toasted.show('Server Error', {
            position: 'top-center',
            duration: 3000,
            variant: 'danger',
          })
        }
      })
    },
    async getCategories() {
      await axios.get('categoryAggregators').then(res => {
        if (res.status === 200) {
          this.categories = res.data?.data
        }
      })
    },
    async getBillers() {
      await axios.get('billers').then(res => {
        if (res.status === 200) {
          this.billers = res.data?.data
        }
      })
    },
    filterTypes(type) {
      const filteredType = this.categories.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style>

</style>
